@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap);
body {
    margin: 0px;
    padding: 0px;
    background-color: #f4f6f8;
    overflow-x: hidden;
}

.pages_page__BTlkY {
    font-family: 'DM Sans';
}

.pages_header__QqU5N {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    /* flex-direction: column; */
    /* alig */
    width: 100%;
    height: 64px;
    padding: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.pages_content__1eOgi {
    height: 100%;
    /* width: 100%; */
    padding: 20px;
}

.pages_logo__VDRtm {
    height: 60%;
    flex-grow: none;
    flex-shrink: n;
    cursor: pointer;
}

.pages_title__3A543 {
    font-size: 24px;
    margin-bottom: 50px;
}

.pages_titleright__2WzmN {
    font-size: 24px;
    margin-bottom: 50px;
    /* text-align: right; */
}

.pages_daterange__XPYz3 {
    font-weight: 500;
    font-family: 'DM Sans';
    font-size: 15px;
}

.pages_daterangeheader__3OWdH {
    font-weight: 500;
    font-family: 'DM Sans';
    font-size: 15px;
    color: #999999;
}

.pages_dropzone__2KmpA {
    width: 200px;
    height: 100px;
    border: 2px dashed #1c315e;
    border-radius: 5px;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    cursor: pointer;
}

.pages_upload__1qdYI {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
}

.pages_rowsCont__9idYO {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.pages_rowsCont__9idYO>.pages_row__279a9 {
    background-color: red;
}

.pages_dr__3BclI {
    margin-bottom: 25px;
}

.pages_drCont__1Had7 {
    /* display: flex; */
    flex-direction: column;
    display: block;
}

.pages_flipButtonAnchor__1t8Or {
    font-size: 15px;
    /* text-decoration: underline; */
    color: #1c2b4a;
    cursor: pointer;
    font-weight: 500;
    text-decoration: underline;
}

.pages_flipButtonSettings__3U_F5 {
    font-size: 15px;
    /* text-decoration: underline; */
    color: #1c2b4a;
    padding-bottom: 10px;
    margin-bottom: 20px;
    cursor: pointer;
    border-radius: 4px;
    /* width: 20vw; */
    padding: 9px;
    flex-grow: none;
    text-align: center;
    /* background-color: red; */
    border: 1px solid #1C315E;
    font-weight: 500;
    margin-right: 5px;
}

.pages_flipButtonSettings__3U_F5:hover {
    background-color: #1C315E;
    color: white;
}

.pages_flipButton__-qExZ {
    font-size: 15px;
    /* text-decoration: underline; */
    color: #1c2b4a;
    padding-bottom: 10px;
    margin-bottom: 20px;
    cursor: pointer;
    border-radius: 4px;
    width: 20vw;
    padding: 10px;
    flex-grow: none;
    text-align: center;
    /* background-color: red; */
    border: 1px solid #1C315E;
    font-weight: 500;
    margin-right: 5px;
}

.pages_flipActive__1J06D {
    background-color: #1c2b4a;
    color: white;
}

.pages_flipContainer__Wuvfs {
    display: flex;
    flex-direction: row;
    /* padding:10px; */
}

.pages_bold__3qQsg {
    font-weight: 400;
    font-size: 12px;
}

.pages_titleRowTable__1GAa0 {
    align-items: baseline;
    width: 100%;
}

.pages_titleRow__nvaHt {
    /* display: flex;
    flex-direction: row;
    width:80vw; */
    /* justify-content: space-between; */
    align-items: baseline;
    width: 100%;
}

.pages_reloadButton__1y0Q6 {
    background-color: #f4f6f8;
    border: 1px solid #1C315E;
    border-radius: 4px;
    padding: 10px;
    font-weight: 500;
    font-family: 'DM Sans';
    color: #1c2b4a;
    width: 100px;
}

.pages_reloadButton__1y0Q6:hover {
    background-color: #1C315E;
    color: white;
}

button {
    display: inline-block;
    /* border: ; */
    padding: 5px 10px;
    /* margin: 0; */
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.pages_buttonCont__2YXYJ {
    display: flex;
}

.pages_row_col_site_name__1l9BQ {
    display: flex;
    flex-direction: column;
    flex: 143% 1;
}

.pages_row_col_hours__1bwlL {
    display: flex;
    flex-direction: column;
    flex: 33% 1;
    min-width: 150px;
    max-width: 150px;
    text-align: right;
}

.pages_searchBox__1wLpS {
    background-color: white;
    font-size: 15px;
    line-height: 18px;
    color: #1c2b4a;
    letter-spacing: -0.04px;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    margin-bottom: 10px;
    margin-top: 20px;
    width: 20%;
    max-width: 50%;
    margin-right: 10px;
    min-width: 240px;
}

input:focus {
    border: none;
    outline-width: 0;
}


/* input.middle:focus{
    outline-width: 0;
} */

.pages_littleRow__GGpVR {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.pages_padding__SFKGU {
    width: 50px;
}

.pages_row_edges__2PN3- {
    display: inline-block;
    flex-direction: row;
    justify-content: space-between;
    width: 1000px;
}

.pages_downloadAsPdf__Wq9Kk {
    text-align: right;
    margin-bottom: -20px;
}

.pages_settingsIconWrap__2yalg {
    /* background-color: #1c2b4a; */
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 50px;
    cursor: pointer;
}

.pages_settingsIconWrap__2yalg svg {
    width: 25px;
    height: 25px;
    fill: #1c2b4a;
    stroke: #1c2b4a;
}
.row_row__10wZK {
    width: 100%;
    background-color: white;
    box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%);
    margin-bottom: 16px;
    border-radius: 4px;
    /* padding: 16px 32px; */
    max-width: 100%;
    padding-top: 16px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 16px;
    box-sizing: border-box;
}

.row_row_main__1JyJy {
    /* display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0px; */
    color: #999999;
    display: flex;
    flex-direction: row;
    width: 99%;
    align-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 12px;
    border-top: 1px solid #999999;
    padding-top: 10px;
}

.row_row_col__1z7Nh {
    display: flex;
    flex-direction: column;
    flex: 33% 1;
}


/* .row_col_hours {
    display: flex;
    flex-direction: column;
    flex: 33%;
    min-width: 150px;
    max-width: 150px;
} */

.row_row_col_map__AQzIl {
    display: flex;
    flex-direction: column;
    flex: 22% 1;
    min-width: 70px;
    max-width: 100px;
}

.row_row_col_staff_type__1Ny5b {
    display: flex;
    flex-direction: column;
    flex: 33% 1;
    min-width: 150px;
    max-width: 150px;
}

.row_row_col_staff_member__M_xfj {
    display: flex;
    flex-direction: column;
    flex: 20% 1;
}

.row_row_title__2pcTi {
    color: #999999;
    font-size: 12px;
    font-weight: 400;
}

.row_row_value__1p1ED {
    color: #1c2b4a;
    font-size: 16px;
    font-weight: 500;
}

.row_row_col_hours__13uYA {
    display: flex;
    flex-direction: column;
    flex: 33% 1;
    min-width: 80px;
    max-width: 150px;
    text-align: right;
}

.row_row_col_time__HLCHO {
    flex: 20% 1;
    min-width: 160px;
    max-width: 170px;
}

@media only screen and (max-width:1200px) {
    .row_row__10wZK {
        max-width: 90vw;
    }
    .row_rowsCont__3g9Gu {
        align-items: center;
    }
}

.row_headers__IgqSG {
    color: #999999;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 12px;
    border-top: 1px solid #999999;
    padding-top: 10px;
}

.row_detailHeader__25RXM {
    /* flex:25%; */
}

.row_row_site__2Za78 {
    width: 100% !important;
    max-width: 100% !important;
}

.row_er_row__3Z5yt {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    color: #FF1D15;
    justify-items: center;
    align-items: center;
    /* align-items: flex-start;
    justify-items: flex-start; */
}

.row_er_col__18XY4 {
    /* align-items: baseline; */
    /* flex:20%; */
    /* background-color: yellow; */
}

.row_svgAlert__3ZWH4 {
    height: 24px;
    width: auto;
}

.row_er_title__3H3bS {
    padding-left: 10px;
}
.MyDropzone_dropzone__1Zymq{
    width:50vw;
    height:100px;
    border: 2px dashed #1c315e;
    border-radius: 5px;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}

.Detail_detail_row__SWqC5 {
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
    text-align: left;
    width: 100%;
    margin-bottom: 5px;
    /* padding:px; */
    border-bottom: 1px solid #d4d4d4;
}

.Detail_detail_alert__2you3 {
    background-color: #ff1d15e8;
    color: white;
    /* border-left: 2px solid #ff1d15; */
}

.Detail_detail__2aajZ {
    flex: 25% 1;
    font-size: 12px;
}

.Detail_row_col_hours__1YvOT {
    display: flex;
    flex-direction: column;
    flex: 33% 1;
    min-width: 80px;
    max-width: 150px;
    text-align: right;
}

.Detail_detail_hours__aok7r {
    flex: 33% 1;
    font-size: 12px;
    min-width: 80px;
    max-width: 150px;
    text-align: right;
    display: flex;
    flex-direction: column;
}

.Detail_detail_map__18kso {
    display: flex;
    flex-direction: column;
    flex: 22% 1;
    min-width: 70px;
    max-width: 100px;
}

.Detail_row_col_hours__1YvOT {
    display: flex;
    flex-direction: column;
    flex: 33% 1;
    min-width: 150px;
    max-width: 150px;
    text-align: right;
}

.Detail_f25__1eaht {
    flex: 25% 1;
}

.Detail_f3__3onql {
    flex: 30% 1;
}

.Detail_f2__1czcN {
    flex: 20% 1;
}

.Detail_text_columns__2HuYW {
    flex: 20% 1;
    min-width: 80px;
}

.Detail_row_col_time__3XrFX {
    flex: 20% 1;
    min-width: 160px;
    max-width: 170px;
}

.Detail_geoLink__3EDuO {
    color: inherit;
    /* text-decoration: inherit; */
}
.DetailToggle_toggle__2Noqq{
    font-size: 12px;
    text-decoration: underline;
    color: #1c2b4a;
    padding-bottom: 10px;
    cursor: pointer;
    border-radius: 4px;
    /* border:2 px solid #1c2b4a; */
    width:100%;
    text-align: right;

}
.settings_settingsWrapper__2_9Ee {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.settings_settingsModal__39OCz {
    background-color: white;
    border-radius: 4px;
    width: 50%;
    height: auto;
    z-index: 101;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    padding: 20px;
    border-radius: 4px;
}

.settings_settingsTitle__2Vr9r {
    font-size: 24px;
    margin-bottom: 20px;
}

.settings_settingsRadio__tUTG5 {
    list-style: none;
    list-style-type: none;
}

.settings_format_setting_table__2QQ3X {
    width: 100%;
}

.settings_format_setting_table_first_td__2Q2dD {
    width: 350px;
}
@font-face {
	font-family: "DMSans Bold";
	src: local("DMSans-Bold"),
		url(/static/media/DMSans-Bold.bc558944.ttf) format("truetype");
}

@font-face {
	font-family: "DMSans BoldItalic";
	src: local("DMSans-BoldItalic"),
		url(/static/media/DMSans-BoldItalic.6db47243.ttf) format("truetype");
}

@font-face {
	font-family: "DMSans Medium";
	src: local("DMSans-Medium"),
		url(/static/media/DMSans-Medium.b0c37609.ttf) format("truetype");
}

@font-face {
	font-family: "DMSans MediumItalic";
	src: local("DMSans-MediumItalic"),
		url(/static/media/DMSans-MediumItalic.60e4afed.ttf) format("truetype");
}

@font-face {
	font-family: "DMSans Regular";
	src: local("DMSans-Regular"),
		url(/static/media/DMSans-Regular.8f90e0cc.ttf) format("truetype");
}

@font-face {
	font-family: "DMSans RegularItalic";
	src: local("DMSans-RegularItalic"),
		url(/static/media/DMSans-RegularItalic.ad51d3ac.ttf) format("truetype");
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	background-color: #f4f6f8;
	height: 100%;
}

a {
	text-decoration: none;
}

#root {
	height: 100%;
}

.MuiBottomNavigationAction-label.Mui-selected {
	font-size: 0.6rem !important;
}

/* Fab Drawer - Fix for iOS overflow bug */
.MuiDrawer-root.MuiDrawer-modal .MuiDrawer-paper {
	overflow-y: inherit;
}

/* iOS 'Add to Home Screen' prompt */
.add-to-home-banner {
	color: #1c2b4a;
	padding: 10px;
}

.add-to-home-banner .add-to-home-icon {
	padding: 0 !important;
}

.add-to-home-banner .add-to-home-close-btn {
	right: -5px;
}

