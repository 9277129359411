.detail_row {
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
    text-align: left;
    width: 100%;
    margin-bottom: 5px;
    /* padding:px; */
    border-bottom: 1px solid #d4d4d4;
}

.detail_alert {
    background-color: #ff1d15e8;
    color: white;
    /* border-left: 2px solid #ff1d15; */
}

.detail {
    flex: 25%;
    font-size: 12px;
}

.row_col_hours {
    display: flex;
    flex-direction: column;
    flex: 33%;
    min-width: 80px;
    max-width: 150px;
    text-align: right;
}

.detail_hours {
    flex: 33%;
    font-size: 12px;
    min-width: 80px;
    max-width: 150px;
    text-align: right;
    display: flex;
    flex-direction: column;
}

.detail_map {
    display: flex;
    flex-direction: column;
    flex: 22%;
    min-width: 70px;
    max-width: 100px;
}

.row_col_hours {
    display: flex;
    flex-direction: column;
    flex: 33%;
    min-width: 150px;
    max-width: 150px;
    text-align: right;
}

.f25 {
    flex: 25%;
}

.f3 {
    flex: 30%;
}

.f2 {
    flex: 20%;
}

.text_columns {
    flex: 20%;
    min-width: 80px;
}

.row_col_time {
    flex: 20%;
    min-width: 160px;
    max-width: 170px;
}

.geoLink {
    color: inherit;
    /* text-decoration: inherit; */
}