.dropzone{
    width:50vw;
    height:100px;
    border: 2px dashed #1c315e;
    border-radius: 5px;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}
