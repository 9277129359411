@font-face {
	font-family: "DMSans Bold";
	src: local("DMSans-Bold"),
		url(./fonts/DM_Sans/DMSans-Bold.ttf) format("truetype");
}

@font-face {
	font-family: "DMSans BoldItalic";
	src: local("DMSans-BoldItalic"),
		url(./fonts/DM_Sans/DMSans-BoldItalic.ttf) format("truetype");
}

@font-face {
	font-family: "DMSans Medium";
	src: local("DMSans-Medium"),
		url(./fonts/DM_Sans/DMSans-Medium.ttf) format("truetype");
}

@font-face {
	font-family: "DMSans MediumItalic";
	src: local("DMSans-MediumItalic"),
		url(./fonts/DM_Sans/DMSans-MediumItalic.ttf) format("truetype");
}

@font-face {
	font-family: "DMSans Regular";
	src: local("DMSans-Regular"),
		url(./fonts/DM_Sans/DMSans-Regular.ttf) format("truetype");
}

@font-face {
	font-family: "DMSans RegularItalic";
	src: local("DMSans-RegularItalic"),
		url(./fonts/DM_Sans/DMSans-RegularItalic.ttf) format("truetype");
}
