.row {
    width: 100%;
    background-color: white;
    box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%);
    margin-bottom: 16px;
    border-radius: 4px;
    /* padding: 16px 32px; */
    max-width: 100%;
    padding-top: 16px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 16px;
    box-sizing: border-box;
}

.row_main {
    /* display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0px; */
    color: #999999;
    display: flex;
    flex-direction: row;
    width: 99%;
    align-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 12px;
    border-top: 1px solid #999999;
    padding-top: 10px;
}

.row_col {
    display: flex;
    flex-direction: column;
    flex: 33%;
}


/* .row_col_hours {
    display: flex;
    flex-direction: column;
    flex: 33%;
    min-width: 150px;
    max-width: 150px;
} */

.row_col_map {
    display: flex;
    flex-direction: column;
    flex: 22%;
    min-width: 70px;
    max-width: 100px;
}

.row_col_staff_type {
    display: flex;
    flex-direction: column;
    flex: 33%;
    min-width: 150px;
    max-width: 150px;
}

.row_col_staff_member {
    display: flex;
    flex-direction: column;
    flex: 20%;
}

.row_title {
    color: #999999;
    font-size: 12px;
    font-weight: 400;
}

.row_value {
    color: #1c2b4a;
    font-size: 16px;
    font-weight: 500;
}

.row_col_hours {
    display: flex;
    flex-direction: column;
    flex: 33%;
    min-width: 80px;
    max-width: 150px;
    text-align: right;
}

.row_col_time {
    flex: 20%;
    min-width: 160px;
    max-width: 170px;
}

@media only screen and (max-width:1200px) {
    .row {
        max-width: 90vw;
    }
    .rowsCont {
        align-items: center;
    }
}

.headers {
    color: #999999;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 12px;
    border-top: 1px solid #999999;
    padding-top: 10px;
}

.detailHeader {
    /* flex:25%; */
}

.row_site {
    width: 100% !important;
    max-width: 100% !important;
}

.er_row {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    color: #FF1D15;
    justify-items: center;
    align-items: center;
    /* align-items: flex-start;
    justify-items: flex-start; */
}

.er_col {
    /* align-items: baseline; */
    /* flex:20%; */
    /* background-color: yellow; */
}

.svgAlert {
    height: 24px;
    width: auto;
}

.er_title {
    padding-left: 10px;
}