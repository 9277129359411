.settingsWrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.settingsModal {
    background-color: white;
    border-radius: 4px;
    width: 50%;
    height: auto;
    z-index: 101;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    padding: 20px;
    border-radius: 4px;
}

.settingsTitle {
    font-size: 24px;
    margin-bottom: 20px;
}

.settingsRadio {
    list-style: none;
    list-style-type: none;
}

.format_setting_table {
    width: 100%;
}

.format_setting_table_first_td {
    width: 350px;
}