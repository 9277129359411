@import "fonts.css";

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	background-color: #f4f6f8;
	height: 100%;
}

a {
	text-decoration: none;
}

#root {
	height: 100%;
}

.MuiBottomNavigationAction-label.Mui-selected {
	font-size: 0.6rem !important;
}

/* Fab Drawer - Fix for iOS overflow bug */
.MuiDrawer-root.MuiDrawer-modal .MuiDrawer-paper {
	overflow-y: inherit;
}

/* iOS 'Add to Home Screen' prompt */
.add-to-home-banner {
	color: #1c2b4a;
	padding: 10px;
}

.add-to-home-banner .add-to-home-icon {
	padding: 0 !important;
}

.add-to-home-banner .add-to-home-close-btn {
	right: -5px;
}
