.toggle{
    font-size: 12px;
    text-decoration: underline;
    color: #1c2b4a;
    padding-bottom: 10px;
    cursor: pointer;
    border-radius: 4px;
    /* border:2 px solid #1c2b4a; */
    width:100%;
    text-align: right;

}