@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
body {
    margin: 0px;
    padding: 0px;
    background-color: #f4f6f8;
    overflow-x: hidden;
}

.page {
    font-family: 'DM Sans';
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    /* flex-direction: column; */
    /* alig */
    width: 100%;
    height: 64px;
    padding: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.content {
    height: 100%;
    /* width: 100%; */
    padding: 20px;
}

.logo {
    height: 60%;
    flex-grow: none;
    flex-shrink: n;
    cursor: pointer;
}

.title {
    font-size: 24px;
    margin-bottom: 50px;
}

.titleright {
    font-size: 24px;
    margin-bottom: 50px;
    /* text-align: right; */
}

.daterange {
    font-weight: 500;
    font-family: 'DM Sans';
    font-size: 15px;
}

.daterangeheader {
    font-weight: 500;
    font-family: 'DM Sans';
    font-size: 15px;
    color: #999999;
}

.dropzone {
    width: 200px;
    height: 100px;
    border: 2px dashed #1c315e;
    border-radius: 5px;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    cursor: pointer;
}

.upload {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
}

.rowsCont {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.rowsCont>.row {
    background-color: red;
}

.dr {
    margin-bottom: 25px;
}

.drCont {
    /* display: flex; */
    flex-direction: column;
    display: block;
}

.flipButtonAnchor {
    font-size: 15px;
    /* text-decoration: underline; */
    color: #1c2b4a;
    cursor: pointer;
    font-weight: 500;
    text-decoration: underline;
}

.flipButtonSettings {
    font-size: 15px;
    /* text-decoration: underline; */
    color: #1c2b4a;
    padding-bottom: 10px;
    margin-bottom: 20px;
    cursor: pointer;
    border-radius: 4px;
    /* width: 20vw; */
    padding: 9px;
    flex-grow: none;
    text-align: center;
    /* background-color: red; */
    border: 1px solid #1C315E;
    font-weight: 500;
    margin-right: 5px;
}

.flipButtonSettings:hover {
    background-color: #1C315E;
    color: white;
}

.flipButton {
    font-size: 15px;
    /* text-decoration: underline; */
    color: #1c2b4a;
    padding-bottom: 10px;
    margin-bottom: 20px;
    cursor: pointer;
    border-radius: 4px;
    width: 20vw;
    padding: 10px;
    flex-grow: none;
    text-align: center;
    /* background-color: red; */
    border: 1px solid #1C315E;
    font-weight: 500;
    margin-right: 5px;
}

.flipActive {
    background-color: #1c2b4a;
    color: white;
}

.flipContainer {
    display: flex;
    flex-direction: row;
    /* padding:10px; */
}

.bold {
    font-weight: 400;
    font-size: 12px;
}

.titleRowTable {
    align-items: baseline;
    width: 100%;
}

.titleRow {
    /* display: flex;
    flex-direction: row;
    width:80vw; */
    /* justify-content: space-between; */
    align-items: baseline;
    width: 100%;
}

.reloadButton {
    background-color: #f4f6f8;
    border: 1px solid #1C315E;
    border-radius: 4px;
    padding: 10px;
    font-weight: 500;
    font-family: 'DM Sans';
    color: #1c2b4a;
    width: 100px;
}

.reloadButton:hover {
    background-color: #1C315E;
    color: white;
}

button {
    display: inline-block;
    /* border: ; */
    padding: 5px 10px;
    /* margin: 0; */
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.buttonCont {
    display: flex;
}

.row_col_site_name {
    display: flex;
    flex-direction: column;
    flex: 143%;
}

.row_col_hours {
    display: flex;
    flex-direction: column;
    flex: 33%;
    min-width: 150px;
    max-width: 150px;
    text-align: right;
}

.searchBox {
    background-color: white;
    font-size: 15px;
    line-height: 18px;
    color: #1c2b4a;
    letter-spacing: -0.04px;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    margin-bottom: 10px;
    margin-top: 20px;
    width: 20%;
    max-width: 50%;
    margin-right: 10px;
    min-width: 240px;
}

input:focus {
    border: none;
    outline-width: 0;
}


/* input.middle:focus{
    outline-width: 0;
} */

.littleRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.padding {
    width: 50px;
}

.row_edges {
    display: inline-block;
    flex-direction: row;
    justify-content: space-between;
    width: 1000px;
}

.downloadAsPdf {
    text-align: right;
    margin-bottom: -20px;
}

.settingsIconWrap {
    /* background-color: #1c2b4a; */
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 50px;
    cursor: pointer;
}

.settingsIconWrap svg {
    width: 25px;
    height: 25px;
    fill: #1c2b4a;
    stroke: #1c2b4a;
}